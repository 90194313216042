<template>
  <div
    v-if="isShow"
    class="notification"
    :style="{ backgroundColor: '#92C4FF' }"
  >
    <div class="notification__title">
      <slot name="notification_title"></slot>
    </div>
    <div class="notification__text">
      <slot name="notification_text"></slot>
    </div>
  </div>
</template>

<script>
import smbMethods from "@/mixins/smbMethods";
export default {
  name: "SmbNotificationBar",
  mixins: [smbMethods],
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.notification {
  background: rgba(146, 196, 255, 0.2);
  padding: 12px 20px;
  display: flex;
  flex-direction: column;
  &__title {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    padding-bottom: 10px;
  }
  &__text {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
}
</style>
