<template>
  <div>
    <div
      class="referral-scroll-panel"
      v-scroll-to="{ el: '#referralContainer', offset: -100 }"
    >
      <div class="referral-scroll-panel__top">
        <div class="title">
          Refer a friend today and both of you win! 🎁
          <span class="title__more">Learn more</span>
        </div>
      </div>
    </div>
    <div class="referral">
      <div class="referral__body">
        <div class="referral__block">
          <div class="block-item steps-block">
            <div class="referral__top">
              <div class="title">
                Refer a friend today and both of you win! 🎁
              </div>
            </div>
            <div class="description">
              When you refer a real-estate agent you get 50% off from your next
              invoice (up to $50) and they get 25% off for the first 3 months!
            </div>
            <div class="steps">
              <div class="steps__item">
                <div class="steps__item-num">1</div>
                <div class="steps__item-data">
                  <div class="title">Invite your colleague</div>
                </div>
              </div>
              <div class="steps__item">
                <div class="steps__item-num">2</div>
                <div class="steps__item-data">
                  <div class="title">They get 25% off for 3 months!</div>
                </div>
              </div>
              <div class="steps__item">
                <div class="steps__item-num">3</div>
                <div class="steps__item-data">
                  <div class="title">
                    You get 50% off on your next invoice 🎉
                  </div>
                  <div class="desc">After they start their 2nd month.</div>
                </div>
              </div>
            </div>
          </div>
          <div class="block-item invite-item">
            <div class="details">
              <div class="details__title">Add colleague details</div>
              <v-form
                ref="form"
                v-model="isValid"
                lazy-validation
                class="details__form"
              >
                <div class="form-control">
                  <span class="form-control__name">Full name</span>
                  <v-text-field
                    v-model="referralForm.fullName"
                    required
                    :rules="[rules.text, rules.required]"
                    solo
                    label="e.g. John Doe"
                  ></v-text-field>
                </div>
                <div class="form-control">
                  <span class="form-control__name">Email</span>
                  <v-text-field
                    v-model="referralForm.email"
                    required
                    :rules="[rules.email, rules.required]"
                    solo
                    label="name@company.com"
                  ></v-text-field>
                </div>
                <div class="form-control">
                  <span class="form-control__name">Phone Number</span>
                  <v-text-field
                    class="prefix-text-field"
                    v-model="referralForm.phone"
                    required
                    type="number"
                    :rules="[rules.required, rules.phoneLength]"
                    solo
                    prefix="+1"
                    label="Add your phone number"
                  ></v-text-field>
                </div>
                <div class="form-control">
                  <span class="form-control__name">Business name</span>
                  <v-text-field
                    v-model="referralForm.businessName"
                    required
                    solo
                    label="e.g. John Realty or Re/Max"
                  ></v-text-field>
                </div>
                <div class="btn-wrapper">
                  <button
                    class="btn"
                    :disabled="!isFormValid"
                    @click.prevent="sendInvite()"
                  >
                    <span v-if="!isLoading">Invite</span>
                    <span v-else class="btn__loader"></span>
                    <span v-if="isSuccess" class="btn__success">
                      Thank You!
                    </span>
                  </button>
                </div>
              </v-form>
            </div>
            <div class="separator">
              <span>or share your code</span>
            </div>
            <div class="share">
              <div class="form-control promo-code">
                <v-text-field
                  readonly
                  v-model="referralCode"
                  solo
                ></v-text-field>
                <button class="copy" @click.prevent="copy()">
                  <span v-if="!isCopy">copy</span>
                  <i v-else class="icon-check-mark"></i>
                </button>
              </div>
              <button @click="share()" class="btn">
                Share <i class="icon-share-up"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import validationRules from "@/shared/validationRules";
import { mapActions } from "vuex";

export default {
  name: "Referrals",
  props: {
    customerName: {
      type: String,
      default: "",
    },
    code: {
      type: String,
      default: "",
    },
    referrerEmail: {
      type: String,
      default: "",
    },
    customerPersonalName: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    isValid: false,
    isFormValid: false,
    isLoading: false,
    isSuccess: false,
    isCopy: false,
    isCickedOnShareBtn: false,
    referralForm: {
      email: "",
      fullName: "",
      businessName: "",
      phone: null,
    },
    pageHeight: null,
  }),
  mixins: [validationRules],
  watch: {
    isValid: function () {
      this.isFormValid = this.$refs.form.inputs.every((v) => v.valid === true);
    },
  },
  computed: {
    referralCode() {
      return this.code;
    },
    shareText() {
      return `Struggling with social media? \nI found the perfect fix - Social Wonder, the AI social media assistant for real-estate pros! \nSign up with my referral code ${this.referralCode} and enjoy 25% off for the first 3 months! \nwww.social-wonder.com`;
    },
  },
  methods: {
    ...mapActions("referrals", ["sendReferralForm"]),
    copy() {
      this.isCopy = true;
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;

      clipboardData.writeText(this.shareText);
      setTimeout(() => {
        this.isCopy = false;
      }, 3000);
    },
    async sendInvite() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        const params = {
          email: this.referralForm.email,
          fullName: this.referralForm.fullName,
          businessName: this.referralForm.businessName || " ",
          phone: this.referralForm.phone,
          referrer: this.customerName,
          code: this.code,
          referrer_email: this.referrerEmail,
        };
        try {
          await this.sendReferralForm(params);
          this.isLoading = false;
          this.isSuccess = true;
          this.isFormValid = false;
          this.referralForm.email = "";
          this.referralForm.fullName = "";
          this.referralForm.businessName = "";
          this.referralForm.phone = null;
          this.$refs.form.resetValidation();
          setTimeout(() => {
            this.isSuccess = false;
          }, 5000);
        } catch (e) {
          console.log(e);
        }
      }
    },
    async share() {
      if (this.$device.mobile) {
        const shareData = {
          text: this.shareText,
        };
        await navigator.share(shareData);
      } else {
        window.open(
          `mailto:?subject=Special%20Offer%20from%20${
            this.customerPersonalName
          }:%20Get%2025%25%20off%20for%203%20months%20on%20AI%20Social%20Media%20Assistant&body=${encodeURIComponent(
            this.shareText
          )}`,
          "_blank"
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/vars";
@import "@/assets/styles/mixins";

.referral-scroll-panel {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: #ffffff no-repeat;
  background-position: 100% -23px;
  background-size: 88px 62px;
  border: 1px solid #92c4ff;
  border-bottom: none;
  box-sizing: border-box;
  cursor: pointer;
  @media screen and (min-width: 600px) {
    .title {
      font-size: 19px !important;
    }
  }

  &__top {
    padding: 5px 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    .title {
      font-weight: bold;
      font-size: 16px !important;
      line-height: 140%;
      color: $color-black;
      &__more {
        font-size: 14px;
        text-decoration: underline;
        font-weight: 700;
        color: #3771c8;
      }
    }
  }
}

.referral {
  position: relative;
  z-index: 10;
  background: #ffffff no-repeat;
  background-position: 100% 30px;
  background-size: 121px 86px;
  border-top: 1px solid #92c4ff;
  border-bottom: none;
  box-sizing: border-box;

  &__block {
    @media screen and (min-width: 760px) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      .block-item {
        width: 100%;
        max-width: 500px;
        &.invite-item {
          margin-left: 50px;
        }
      }
    }
    .block-item {
      &.steps-block {
        background-image: url("../../assets/img/illustration.svg");
        background-repeat: no-repeat;
        background-size: 192px;
        background-position: bottom 0 right 0;
        padding-bottom: 60px;
      }
    }
  }

  &__top {
    //padding: 5px 16px;
    @media screen and (min-width: 760px) {
      padding-bottom: 16px;
    }
    .title {
      font-size: 16px !important;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      color: $color-black;
      @media screen and (min-width: 760px) {
        font-size: 24px !important;
      }
    }
  }

  &__body {
    width: 100%;
    padding: 25px 20px 40px 20px;

    .description {
      font-size: 14px;
      line-height: 160%;
      color: #1f2325;
      opacity: 0.8;
      margin-bottom: 6px;
      padding-right: 78px;
      @media screen and (min-width: 760px) {
        padding-right: 50px;
      }
    }

    .more-info {
      font-size: 14px;
      line-height: 160%;
      text-decoration-line: underline;
      color: #7e5689;
      opacity: 0.8;
      cursor: pointer;
    }

    .steps {
      display: flex;
      flex-direction: column;
      margin-top: 36px;
      margin-bottom: 40px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: 24px;
        height: 100%;
        top: 0;
        width: 2px;
        background: linear-gradient(to top, transparent 50%, #fff 50%),
          linear-gradient(to top, #c7c9ca, #c7c9ca);
        background-size: 2px 20px, 2px 20px;
      }

      &__item {
        display: flex;
        align-items: center;
        margin-bottom: 60px;

        &:last-child {
          margin-bottom: 0;
        }

        &:nth-child(2) {
          .steps__item-num {
            color: #dc7c73;
          }
        }

        &:nth-child(3) {
          .steps__item-num {
            color: #b76988;
          }
        }

        &-num {
          border: 1px solid #eeeff0;
          box-sizing: border-box;
          box-shadow: 0px 2px 12px rgba(31, 35, 37, 0.06);
          background: #fff;
          position: relative;
          z-index: 10;
          width: 48px;
          height: 48px;
          border-radius: 50%;
          flex-shrink: 0;
          margin-right: 16px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          font-size: 24px;
          line-height: 140%;
          color: #e69d5b;
        }

        &-data {
          display: inline-flex;
          flex-direction: column;

          .title {
            font-weight: bold;
            font-size: 18px;
            line-height: 140%;
            color: $color-black;
          }

          .desc {
            font-size: 14px;
            line-height: 160%;
            margin-top: 2px;
          }
        }
      }
    }
    .prefix-text-field {
      ::v-deep {
        .v-label {
          left: 25px !important;
        }
      }
    }

    .details {
      display: flex;
      flex-direction: column;

      &__title {
        font-weight: bold;
        font-size: 18px;
        line-height: 140%;
        color: #1f2325;
        margin-bottom: 16px;
      }

      &__form {
        display: flex;
        flex-direction: column;
        padding: 0 8px;
      }

      .form-control {
        display: flex;
        flex-direction: column;

        &__name {
          font-weight: 500;
          font-size: 12px;
          line-height: 140%;
          margin-bottom: 4px;
        }

        ::v-deep {
          .v-input__slot {
            box-shadow: none;
            background: #fafafa;
            border: 1px solid #c7c9ca;
            box-sizing: border-box;
            border-radius: 4px;
            margin-bottom: 3px;
          }

          .v-text-field__details {
            margin-left: 0;
            margin-top: 5px;
            padding-left: 0;

            .v-messages__message {
              color: $color-red;
            }
          }

          .v-input__control {
            min-height: 44px;
          }

          .v-label {
            font-size: 14px;
          }
        }
      }

      .btn-wrapper {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 18px 0 28px 0;
      }
    }

    .btn {
      width: 100%;
      height: 44px;
      background: #7e5689;
      border-radius: 16px;
      display: inline-flex;
      overflow: hidden;

      span {
        color: #ffffff;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
      }

      &__success {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 20;
        background: #7e5689;
      }

      &[disabled] {
        background: #eeeff0;
        border-radius: 16px;

        span {
          color: #8f9192;
        }
        .btn__success {
          background: #7e5689 !important;
          color: #fff;
        }
      }
    }

    .separator {
      text-align: center;
      position: relative;
      margin: 0 8px 28px 8px;

      span {
        color: rgba(31, 35, 37, 0.8);
        font-size: 14px;
        line-height: 160%;
        display: inline-flex;
        padding: 0 16px;
        position: relative;
        background: #fff;
        z-index: 2;
      }

      &:before {
        content: "";
        width: 100%;
        display: block;
        position: absolute;
        top: 12px;
        left: 0;
        right: 0;
        height: 1px;
        background: #c7c9ca;
      }
    }

    .share {
      padding: 0 8px;

      .form-control {
        position: relative;

        .copy {
          display: inline-flex;
          position: absolute;
          right: 16px;
          top: 11px;
          text-align: center;
          align-items: center;
          justify-content: center;
          z-index: 2;
          font-weight: bold;
          font-size: 14px;
          line-height: 140%;
          color: $color-smb-black;
          width: 33px;
          i {
            position: absolute;
            top: 5px;
          }

          &[disabled] {
            color: #8f9192;
          }
        }

        &__name {
          font-weight: 500;
          font-size: 12px;
          line-height: 140%;
          margin-bottom: 4px;
          display: inline-flex;
        }

        ::v-deep {
          .v-input__slot {
            box-shadow: none;
            background: #fafafa;
            border: 1px solid #c7c9ca;
            box-sizing: border-box;
            border-radius: 4px;
            margin-bottom: 3px;
          }

          .v-text-field__details {
            margin-left: 0;
            margin-top: 5px;
            padding-left: 0;

            .v-messages__message {
              color: $color-red;
            }
          }

          .v-input__control {
            min-height: 44px;
          }

          .v-label {
            font-size: 14px;
          }
        }

        &.promo-code {
          ::v-deep {
            .v-input__slot {
              box-shadow: none;
              background: rgb(0, 0, 0, 0.1);
              border: none;
              box-sizing: border-box;
              border-radius: 4px;
              margin-bottom: 16px;
            }

            .v-text-field__details {
              display: none;
            }

            .v-input__control {
              min-height: 44px;
            }

            .v-label {
              font-size: 14px;
            }

            .v-input input {
              color: #000000;
              font-size: 14px;
            }

            .v-input--is-disabled {
              .v-input__slot {
                background: #eeeff0;

                .v-input input {
                  color: #8f9192;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }

      .btn {
        background: transparent;
        border: 1px solid $color-smb-black;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        text-align: center;
        color: $color-smb-black;

        i {
          margin-left: 8px;
        }

        &[disabled] {
          border: 1px solid #c7c9ca;
          color: #8f9192;
        }
      }
    }
  }

  &.show {
    position: static;

    .referral {
      &__body {
        display: block;
      }
    }
  }
}
</style>
