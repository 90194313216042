<template>
  <div
    v-if="(isNewUser && isShowTasks) || isNeedToConnectSocialNetworks"
    class="tasks"
  >
    <div class="tasks__title">Open items</div>

    <ul class="tasks__list">
      <li v-if="isNewUser && isShowTasks" class="tasks__list-item">
        Update your brands colors/logo/etc
      </li>
      <li v-if="isNeedToConnectSocialNetworks" class="tasks__list-item">
        Connect your social accounts
      </li>
    </ul>
    <div>
      <v-btn
        v-if="!isNeedToUpdateBothSocialsAndBranding"
        class="tasks__btn"
        @click="clickOnSettingsButton"
        >Go to settings
      </v-btn>
      <div v-else>
        <v-btn
          class="tasks__btn without-static-width"
          @click="connectSocialPlatformsHandler"
          >Connect social accounts
        </v-btn>
        <v-btn
          class="tasks__btn without-static-width"
          @click="$router.push({ name: 'profile-settings' })"
          >Update branding
        </v-btn>
      </div>
    </div>
    <ModalDialog
      :isShow="connectIgDialog.isShow"
      customClass="connect-facebook"
      :size="connectIgDialog.size"
      :footerButtons="connectIgDialog.footerButtons"
      @success="redirectToConnectSocialsService"
      @cancel="connectIgDialog.isShow = false"
      @close="connectIgDialog.isShow = false"
    >
      <template v-slot:description>
        <div class="connect-info">
          <div class="connect-info__title">Authenticate with Facebook</div>
          <div class="connect-info__text">
            <h5>Why do I have to authenticate with Facebook?</h5>
            <p class="mb20">
              Facebook requires
              <span class="underline"
                >Instagram business accounts to be connected to a Facebook
                Page</span
              >. For us to be able to post on your behalf, we need to
              authenticate through Facebook.
            </p>
            <h5>Want to post only to Instagram?</h5>
            <p class="mb20">
              No problem, authenticate with Facebook, and select only your
              Instagram account from the Facebook selection menu.
            </p>
            <h5>So what’s next?</h5>
            <p class="mb20">
              Click the button below, sign in to the Facebook profile that has
              Admin permissions for the Facebook Page connected with your
              Instagram account.
            </p>
            <h5>Need help?</h5>
            <p class="mb20">
              Simply send us a message to support@social-wonder.com and we’ll
              schedule a short Zoom (usually takes less than 3 minutes) to help
              you set up everything.
            </p>
          </div>
        </div>
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import smbMethods from "@/mixins/smbMethods";
import ModalDialog from "@/components/global/ModalDialog.vue";
export default {
  name: "SmbRequiredSteps",
  data: () => ({
    connectIgDialog: {
      isShow: false,
      size: 433,
      footerButtons: [
        {
          name: "Cancel",
          emitName: "cancel",
          btnClass: "border",
        },
        {
          name: "Authenticate with Facebook",
          emitName: "success",
          btnClass: "black",
        },
      ],
    },
  }),
  components: { ModalDialog },
  mixins: [smbMethods],
  methods: {
    clickOnSettingsButton() {
      if (this.isNeedToConnectSocialNetworks) {
        this.connectSocialPlatformsHandler();
      } else {
        this.$router.push({ name: "profile-settings" });
      }
    },
    redirectToConnectSocialsService() {
      if (this.getCurrentSmbCustomer.saas_redirect_link_list.length > 1) {
        window.open(
          this.getCurrentSmbCustomer.saas_redirect_link_list[0],
          "_blank"
        );
      } else {
        window.open(this.getCurrentSmbCustomer.saas_redirect_link, "_blank");
      }
    },
    connectSocialPlatformsHandler() {
      if (!this.getCurrentSmbCustomer.fb_page_link) {
        this.redirectToConnectSocialsService();
      } else {
        this.connectIgDialog.isShow = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.tasks {
  background: rgba(146, 196, 255, 0.2);
  padding: 12px 78px;
  display: flex;
  flex-direction: column;
  &__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    color: #1f2325;
    opacity: 0.8;
    margin-bottom: 4px;
  }
  &__list {
    display: flex;
    flex-direction: column;
    padding-left: 25px;
    margin-bottom: 20px;
    &-item {
      font-weight: 400;
      font-size: 17px;
      line-height: 140%;
      color: #1f2325;
      opacity: 0.8;
    }
  }
  &__btn {
    background: #f5ede2 !important;
    border-radius: 16px;
    height: 36px;
    text-transform: none;
    letter-spacing: 0;
    max-width: 120px;
    box-shadow: none;
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
}
.without-static-width {
  max-width: unset;
  margin-right: 10px;
}
.connect-info {
  display: flex;
  flex-direction: column;
  text-align: left;

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    color: #1f2325;
    text-align: left;
    padding: 14px 20px 11px 38px;
  }

  &__text {
    background: #f5ede2;
    padding: 17px 22px 10px 36px;

    h5 {
      font-weight: 700;
      font-size: 15px;
      line-height: 150%;
      margin-bottom: 5px;
      color: #000;
    }

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #000;
    }

    .mb20 {
      margin-bottom: 20px;
    }

    .underline {
      text-decoration: underline;
    }
  }
}
</style>
